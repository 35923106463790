import React from "react";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
import Wallet from "../../../Assets/Images/wallet.png";
import Deposit from "../../../Assets/Images/deposit.png";
import Playgame from "../../../Assets/Images/play-games.png";
import Affiliates from "../../../Assets/Images/affiliates.png";
import Promotion from "../../../Assets/Images/promotion.png";

function Footer() {
  return (
    <div className="bg-main-footer">
      <Row justify="center" align="middle" className={css`
              margin-right: 2rem
            }`}>
        <Col span={4}>
          <a href="https://game.lsm99dna.net/login">
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/original-wallet.png"}
              className={css`width: 90%;
              
                        }`}
              alt="..."
            ></img>
          </a>
        </Col>
        <Col span={4}>
          <a href="https://game.lsm99dna.net/login">
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/original-deposit.png"}
              className={css`width: 90%;
                    
             
                        }`}
              alt="..."
            ></img>
          </a>
        </Col>
        <Col span={6}>
          {" "}
          <a href="https://game.lsm99dna.net/login">
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/original-play-games.png"}
              className={css`width: 90%;
                    margin-bottom: 25px;
                    margin-left: 10px;
}
                        }`}
              alt="..."
            ></img>
          </a>
        </Col>
        <Col span={4}>
          <a href="https://game.lsm99dna.net/login">
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/original-affiliates.png"}
              className={css`width: 90%;
                        }`}
              alt="..."
            ></img>
          </a>
        </Col>
        <Col span={4}>
          <a href="/promotion">
            <img
              src={"https://fs.cdnxn.com/landingDna/Images/original-promotion.png"}
              // className={css`
              //       margin-bottom: 8px;
              //       margin-left: 25px;
              //           }`}
              className={css`width: 90%;
                            }`}
              alt="..."
            ></img>
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
