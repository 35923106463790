import React, { useState } from 'react'
import HomeFooter from '../Footer/Footer';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row } from 'antd';
import { css } from '@emotion/css'
import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';
import CarouselCard from "./Component/carouselCard";
import ContentMenu from "./Component/contentMenu";
import Singin from '../../../Assets/Images/button-sigin.png'
import Login from '../../../Assets/Images/button-login.png'
import Line from '../../../Assets/Images/button-line.png'
import BtnClose from '../../../Assets/Images/Icon-Close.png'
import menuGameHot from "../../../Assets/Images/text-gamehot.png";
import menuLookAll from "../../../Assets/Images/text-lookall.png";
const Game1 = "https://fs.cdnxn.com/landingDna/Images/Game/game1.png";
const Game2 = "https://fs.cdnxn.com/landingDna/Images/Game/game2.png";
const Game3 = "https://fs.cdnxn.com/landingDna/Images/Game/game3.png";
const Game4 = "https://fs.cdnxn.com/landingDna/Images/Game/game4.png";
const Game5 = "https://fs.cdnxn.com/landingDna/Images/Game/game5.png";
const Game6 = "https://fs.cdnxn.com/landingDna/Images/Game/game6.png";
const Game7 = "https://fs.cdnxn.com/landingDna/Images/Game/game7.png";
const Game8 = "https://fs.cdnxn.com/landingDna/Images/Game/game8.png";

function Index() {
    const [close, setClose] = useState(true)

    const Gamelist = [
        { game: Game1 },
        { game: Game2 },
        { game: Game3 },
        { game: Game4 },
        { game: Game5 },
        { game: Game6 },
        { game: Game7 },
        { game: Game8 }
    ]

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
        },
        desktop1: {
            breakpoint: { max: 3000, min: 1320 },
            items: 8,
        },
        desktop2: {
            breakpoint: { max: 1320, min: 720 },
            items: 6,
        },
        tablet1: {
            breakpoint: { max: 960, min: 720 },
            items: 4,
        },
        tablet2: {
            breakpoint: { max: 720, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    return (
        <>
            <div className='home-image'>
                <div className="mb">
                    {close === true ? <>

                        <div className="alert-home">
                            <div className="alert-marquee"><Alert
                                message={
                                    <Marquee pauseOnHover gradient={false} speed={70}>
                                        เว็บพนันอันดับ 2 ที่มีครบจบที่เดียวให้นึกถึง Lsm99dna เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย พร้อมด้วยโปรโมชั่นมากมาย ที่รองรับการเล่นของสมาชิกทุกท่าน บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชม. ติดปัญหาส่วนใดเเจ้งแอดมินได้ตลอดเลย
                                    </Marquee>
                                }
                            />
                                <img src={"https://fs.cdnxn.com/landingDna/Images/Icon-Close.png"} onClick={() => setClose(false)} className='icon-close' alt="..." />
                            </div>
                        </div>
                    </> : null


                    }

                </div>
                <div className='mb my-2 mx-3'>
                    <div className="d-flex justify-content-center align-items-center ">
                        {/* <a href='https://game.lsm99dna.net/register' className='me-3'><img src={Singin} className={css`width:100%;
                      
                        }`} alt="..."></img></a> */}
                        <a href='https://game.lsm99dna.net/login'><img src={"https://fs.cdnxn.com/landingDna/Images/button-login.png"} className={css`width:100%;
                         
                        }`} alt="..."></img></a>
                    </div>
                </div>


                <Row justify="center" align="top">
                    <Col xs={20} lg={9} ><CarouselCard /></Col>
                    <Col xs={24} lg={9} ><ContentMenu /></Col>
                    <div className='mb mt-3 mx-3'>
                        <a href='https://line.me/R/ti/p/@lsm99dna' ><img src={"https://fs.cdnxn.com/landingDna/Images/button-line.png"} className={css`width:100%;
                        }`} alt="..."></img>
                        </a>

                    </div>
                </Row>
                <div>
                    <div className="text-game-hot">
                        <img
                            src={"https://fs.cdnxn.com/landingDna/Images/text-gamehot.png"}
                            className="img-game-hot"
                            alt="..."
                        />

                    </div>
                    <div className='carousel-game'>
                        <Carousel
                            draggable={true} responsive={responsive}>
                            {Gamelist.map((item) => (
                                <a href='https://game.lsm99dna.net/login' className="d-flex justify-content-center">
                                    <img src={item.game} className="image-carousel-game" alt="...">
                                    </img>
                                </a>
                            ))}
                        </Carousel>
                    </div>

                    <a href='https://game.lsm99dna.net/login' className="text-look-all">
                        <img
                            src={"https://fs.cdnxn.com/landingDna/Images/text-lookall.png"}
                            className="img-look-all"
                            alt="..."
                        />

                    </a>
                </div>
                <div className='mb'><HomeFooter /></div>

            </div>
        </>
    )
}

export default Index