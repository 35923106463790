import React from "react";
import { MdClose } from "react-icons/md";
import { Col, Row } from "antd";
import { css } from "@emotion/css";
import Wallet from "../../../Assets/Images/wallet.png";
import Deposit from "../../../Assets/Images/deposit.png";
import Playgame from "../../../Assets/Images/play-games.png";
import Affiliates from "../../../Assets/Images/affiliates.png";
import Promotion from "../../../Assets/Images/promotion.png";
import BurgerMenu from "../../../Assets/Images/burger-menu.png";
import Notification from "../../../Assets/Images/notification.png";
import Line from "../../../Assets/Images/line-dna.png";
// import LogoLsm99 from "../../../Assets/Images/logo-dna.png";
import LogoryderPc from "../../../Assets/Images/logo-dna.gif";
// import LogoryderPc from "../../../Assets/Images/logo-dna.png";
import Singin from "../../../Assets/Images/button-sigin.png";
import Login from "../../../Assets/Images/button-login.png";

import BurgerAlliance from "../../../Assets/Images/BurgerAlliance.png";
import BurgerContact from "../../../Assets/Images/BurgerContact.png";
import BurgerDownload from "../../../Assets/Images/BurgerDownload.png";
import BurgerHistory from "../../../Assets/Images/BurgerHistory.png";
import BurgerMoneyback from "../../../Assets/Images/BurgerMoneyback.png";
import BurgerPlayGame from "../../../Assets/Images/BurgerPlayGame.png";
import BurgerPromotion from "../../../Assets/Images/BurgerPromotion.png";

function Header() {
  return (
    <>
      <div className="bg-main-header pc">
        <div className="section-main-header">
          <div className="d-flex align-items-center">
            <a href="/">
              <img src={"https://fs.cdnxn.com/landingDna/Images/logo-dna.png"} className="logoDna-pc" alt="..."></img>
            </a>
            <a href="https://game.lsm99dna.net/register" className="me-3 -cny-top-theme-signin">
              <img src={"https://fs.cdnxn.com/landingDna/Images/button-sigin.png"} alt="..."></img>
            </a>
          </div>
          <div className="memu-navbar-center">
            <Row justify="center" align="middle">
              <Col span={4}>
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/original-wallet.png"}
                    className={css`width: 100%;    margin-top: -9px;
                        }`}
                    alt="..."
                  ></img>
                </a>
              </Col>
              <Col span={4}>
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/original-deposit.png"}
                    className={css`width: 115%;margin-top: -1px;
                        }`}
                    alt="..."
                  ></img>
                </a>
              </Col>
              <Col span={6}>
                {" "}
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/original-play-games.png"}
                    className={css`width: 90%;
                    margin-bottom: 25px;
                    margin-left: 10px;
}
                        }`}
                    alt="..."
                  ></img>
                </a>
              </Col>
              <Col span={4}>
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/original-affiliates.png"}
                    className={css`width: 115%;
                    margin-bottom: -8px;
                        }`}
                    alt="..."
                  ></img>
                </a>
              </Col>
              <Col span={4}>
                <a href="/promotion">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/original-promotion.png"}
                    // className={css`
                    // margin-bottom: 10px;
                    // margin-left: 25px;
                    //     }`}
                    className={css`width: 100%;
                            }`}
                    alt="..."
                  ></img>
                </a>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-items-center">
            <a href="https://game.lsm99dna.net/login" className="mx-1">
              <img src={"https://fs.cdnxn.com/landingDna/Images/button-login.png"} alt="..."></img>
            </a>
            <a href="https://line.me/R/ti/p/@lsm99dna" className=" mx-1 pc ">
              <img src={"https://fs.cdnxn.com/landingDna/Images/line-dna.png"} alt="..."></img>
            </a>
            <a href="https://game.lsm99dna.net/login" className="mx-1">
              <img src={"https://fs.cdnxn.com/landingDna/Images/notification.png"} alt="..."></img>
            </a>
            <img
              data-toggle="modal"
              data-target="#exampleModal"
              className="burgerbar mx-1"
              src={"https://fs.cdnxn.com/landingDna/Images/burger-menu.png"}
              alt="..."
            >
            </img>
          </div>
        </div>
      </div>
      <div className="bg-main-header mb">
        <div className="section-main-header">
          <div className="d-flex align-items-center">
            <img
              data-toggle="modal"
              data-target="#exampleModal"
              className="burgerbar me-2"
              src={"https://fs.cdnxn.com/landingDna/Images/burger-menu.png"}
              alt="..."
            >
            </img>
            <a href="https://game.lsm99dna.net/login">
              <img src={"https://fs.cdnxn.com/landingDna/Images/notification.png"} alt="..."></img>
            </a>
          </div>
          <div className="logo-navbar-mb">
            <a href="/">
              <img src={"https://fs.cdnxn.com/landingDna/Images/logo-dna.png"} className="logoDna-mb" alt="..."></img>
            </a>
          </div>
          <div>
            <a href="https://line.me/R/ti/p/@lsm99dna" className="mx-1">
              <img src={"https://fs.cdnxn.com/landingDna/Images/line-dna.png"} alt="..."></img>
            </a>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-modal-bar">
            <div class="modal-header">
              <button
                type="button"
                class="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </div>
            <div className="modal-body">
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerPlayGame.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>

                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerMoneyback.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerHistory.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
                <a href="/promotion">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerPromotion.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerAlliance.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
                <a
                  href="https://game.lsm99dna.net/login"
                  className="border-session-menu-bar-2"
                >
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerContact.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
              <div className="session-menu-bar mb-3">
                <a href="https://game.lsm99dna.net/login">
                  <img
                    src={"https://fs.cdnxn.com/landingDna/Images/BurgerDownload.png"}
                    className="Burger-Image"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
